import { Environment } from 'app-models';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  name: 'qa',
  production: true,
  auth: {
    issuer: 'https://et-tractebel.okta-emea.com/oauth2/default',
    clientId: '0oa8490fubMXIqu4z0i7',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['email', 'openid', 'profile', 'offline_access', 'greenbackup'],
  },
  sentry: {
    dsn: 'https://4b3a5bd6380441b18115a93f5e3eea6b@o258615.ingest.sentry.io/6535225',
  },
  api: 'https://api.qa.greenbackup.te-ded.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
